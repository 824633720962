import React from 'react';
import { Col, Container, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import footerImage from '../../assets/footer-image.jpg';
import logoImage from '../../assets/images/isha-logo-black.png';

const PaymentFailure = () => {
  const params = useParams();

  return (
    <>
      <div className="bg-ishaBeige text-black py-10">
        <img alt="Isha-Logo" src={logoImage} className="Isha-Logo---Black" />
      </div>
      <Container>
        <Col
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          xs={{ span: 12 }}
          style={{ backgroundColor: '#e8e8e8' }}
          className={'flex flex-col items-center mt-20 mb-20 p-20 '}>
          <p className="font-medium text-3xl mb-10">Error During Payment</p>
          <svg width={70} height={70} viewBox="0 0 70 70" className="w-16 h-16 mb-10">
            <g
              fill="none"
              fillRule="evenodd"
              stroke="#B32727"
              strokeWidth={5.5}
              transform="translate(3 3)">
              <circle cx={32} cy={32} r={32} />
              <path strokeLinecap="round" d="M18.424 19l27.152 27m0-27L18.424 46" />
            </g>
          </svg>
          <p className="text-kala text-base text-center book-font mb-4" />
          <p>Error message: {params.message}</p>
        </Col>
      </Container>
      <div className="w-full">
        <Image fluid style={{ width: '100vw' }} src={footerImage} />
      </div>
      <div className="bg-black opacity-75 text-white w-full py-4" />
    </>
  );
};

export default PaymentFailure;
