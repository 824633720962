import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';

import Router from './global/routes';


class App extends Component {
  render() {
    return (
      <Container fluid={true} className="App__container">
        <Router />
      </Container>
    );
  }
}

export default App;
