import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { currentDomainUri } from '../../global/util';

function oneTimePayment(
  name,
  email,
  mobile,
  amount,
  currency,
  orderId,
  uniqueId,
  txnId,
  type
) {
  var options = {
    key: process.env.REACT_APP_RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
    amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: currency,
    name: name,
    description: 'Payment',
    image: 'https://example.com/your_logo',
    // eslint-disable-next-line camelcase
    order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    // eslint-disable-next-line camelcase
    callback_url: `${currentDomainUri()}/callback/razorpay/sge/${uniqueId}/${type.toLowerCase()}`,
    timeout: '600',
    prefill: {
      name: name,
      email: email,
      contact: mobile,
    },
    notes: {
      productID: 'SGE',
      subID: uniqueId,
      txnID: txnId,
    },
    theme: {
      color: '#F37254',
    },

    modal: {
      ondismiss: function () {
        console.log('Checkout form closed (or) transaction time exceeded');
        window.location.href = currentDomainUri() + '/aborted/' + uniqueId + '/' + txnId;
      },
    },
  };
  var rzp = new window.Razorpay(options);

  rzp.open();
}

function recurringPayment(
  email,
  orderId,
  uniqueId,
  txnId,
  customerId,
  type
) {
  var options = {
    key: process.env.REACT_APP_RAZORPAY_API_KEY,
    // eslint-disable-next-line camelcase
    order_id: orderId,
    // eslint-disable-next-line camelcase
    customer_id: customerId,
    recurring: '1',
    method: 'card',
    // eslint-disable-next-line camelcase
    callback_url: `${currentDomainUri()}/callback/razorpay/sge/${uniqueId}/${type.toLowerCase()}`,
    timeout: '600',
    notes: {
      productID: 'SGE',
      subID: uniqueId,
      txnID: txnId,
    },
    theme: {
      color: '#F37254',
    },
    modal: {
      ondismiss: function () {
        console.log('Checkout form closed');
        window.location.href = currentDomainUri() + '/aborted/' + uniqueId + '/' + txnId;
      },
    },
  };
  var rzp1 = new window.Razorpay(options);

  rzp1.open();
}

function RazorPay() {
  const [personalInformation, setPersonalInformation] = useState(null);
  const params = useParams();

  const orderId = params.orderId;
  const uniqueId = params.uniqueId;
  const txnId = params.txnId;

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const userData1 = await axios.get('/api/sge/payment-request/' + params.uniqueId);
        const allData = {};

        let userData = userData1.data;

        allData.firstName = userData.firstName;
        allData.lastName = userData.lastName;
        allData.email = userData.email;
        allData.phone = userData.mobilePhone;
        allData.address = userData.addressLine1 + userData.addressLine2;
        allData.city = userData.city;
        allData.state = userData.state;
        allData.zip = userData.postCode;
        allData.country = userData.country;
        allData.amount = userData.amount;
        allData.currency = userData.currency;
        allData.type = userData.type;
        allData.customerid = userData.gatewayCustomerId;
        allData.trialDays = userData.trialDays;
        allData.renewalType = userData.renewalType;
        setPersonalInformation(allData);
      } catch (error) {
        console.log('error in fetching 1030-->', error);
      }
    };

    fetchSubscription();
  }, []);

  return (
    <div>
      <>
        {personalInformation &&
        ((personalInformation.type === 'ANNUAL' &&
        personalInformation.trialDays > 0) || (personalInformation.type === 'ANNUAL' &&
        personalInformation.renewalType === "MANUAL"))
          ? recurringPayment(
            personalInformation.email,
            orderId,
            uniqueId,
            txnId,
            personalInformation.customerid,
            personalInformation.type
          )
          : null}
        {personalInformation && personalInformation.type === 'MONTHLY'
          ? recurringPayment(
            personalInformation.email,
            orderId,
            uniqueId,
            txnId,
            personalInformation.customerid,
            personalInformation.type
          )
          : null}
        {personalInformation &&
        personalInformation.type === 'ANNUAL' &&
        personalInformation.trialDays === 0 &&
        personalInformation.renewalType !== "MANUAL"
          ? oneTimePayment(
            personalInformation.firstName,
            personalInformation.email,
            personalInformation.phone,
            personalInformation.amount,
            personalInformation.currency,
            orderId,
            uniqueId,
            txnId,
            personalInformation.type
          )
          : null}
        {personalInformation && personalInformation.type === 'ONE_TIME'
          ? oneTimePayment(
            personalInformation.firstName,
            personalInformation.email,
            personalInformation.phone,
            personalInformation.amount,
            personalInformation.currency,
            orderId,
            uniqueId,
            txnId,
            personalInformation.type
          )
          : null}
      </>
    </div>
  );
}

export default RazorPay;
