import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

let postUrl = null;



function PayUPage()  {
  const [personalInformation, setPersonalInformation] = useState(null);
  const params = useParams();
  const txnId = params.txnId;
  const hash = params.hash;
  const source = params.source;
  useEffect(() => {
    const fetchSubscription = async () => {
      try {

        const userData1 = await axios.get('/api/payu/checkout-params/' +txnId+'/'+hash+'/'+source)
        let userData = userData1.data;
        console.log(userData);
        document.getElementById('key').value = userData.key;
        document.getElementById('txnid').value = userData.txnid;
        document.getElementById('amount').value = userData.amount;
        document.getElementById('productinfo').value = userData.productinfo;
        document.getElementById('firstname').value = userData.firstname;
        document.getElementById('email').value = userData.email;
        document.getElementById('phone').value = userData.phone;
        document.getElementById('surl').value = userData.surl;
        document.getElementById('furl').value = userData.furl;
        document.getElementById('api_version').value = userData.api_version;
        if (userData.si_details !=null) {
          document.getElementById('si').value = userData.si;
          document.getElementById('udf1').value = userData.udf1;
          document.getElementById('si_details').value = userData.si_details;
        }else{
          document.getElementById('si').remove();
          document.getElementById('udf1').remove();
          document.getElementById('si_details').remove();
        }

        document.getElementById('hash').value = userData.hash;
        document.getElementById('enforce_paymethod').value = userData.enforce_paymethod;

        postUrl = `https://${process.env.REACT_APP_PAYU_HOST}/_payment`;
        document.payTypeForm.action = postUrl;
        document.payTypeForm.method = 'post';
        document.payTypeForm.submit();
      } catch (error) {
        console.log('error in fetching 1030-->', error);
      }
    };

    fetchSubscription();

  }, []);

  return (
      <>
        <form name="payTypeForm" action="" method="post" className={'w-full'}>
          <input type="hidden" name="key" id="key" value="" />
          <input type="hidden" name="txnid" id="txnid" value="" />
          <input type="hidden" name="amount" id="amount" value="" />
          <input type="hidden" name="productinfo" id="productinfo" value="" />
          <input type="hidden" name="firstname" id="firstname" value="" />
          <input type="hidden" name="email" id="email" value="" />
          <input type="hidden" name="phone" id="phone" value="" />
          <input type="hidden" name="surl" id="surl" value="" />
          <input type="hidden" name="furl" id="furl" value="" />
          <input type="hidden" name="api_version" id="api_version" value="" />
          <input type="hidden" name="si" id="si" value="" />
          <input type="hidden" name="udf1" id="udf1" value="" />
          <input type="hidden" name="si_details" id="si_details" value="" />
          <input type="hidden" name="hash" id="hash" value="" />
          <input type="hidden" name="enforce_paymethod" id="enforce_paymethod" value="" />
        </form>
      </>
  );
}
export default PayUPage;
