import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {ScaleLoader} from "react-spinners";
import {loadStripe} from '@stripe/stripe-js/pure';


function renderSpinner() {
    return (
        <Row className="show-grid" float="center">
            <Col className="d-flex justify-content-center">
                <ScaleLoader size={150} color={"#bc3e04"} loading={true}/>
            </Col>
        </Row>
    )
}

function Stripe(props) {
    useEffect(() => {
        (async () => {
            const providerId = props.match.params.providerId;
            const sessionId = props.match.params.sessionId;
            const pk = process.env[`REACT_APP_STRIPE_KEY_${providerId}`];

            const stripe = await loadStripe(pk);
            const {error} = await stripe.redirectToCheckout({sessionId});
            console.log(error);
        })();
    }, []);

    return (
        renderSpinner()
    )
}

export default Stripe;
