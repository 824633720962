import React from 'react';
import { Col, Container, Image } from 'react-bootstrap';

import footerImage from '../../assets/footer-image.jpg';
import logoImage from '../../assets/images/isha-logo-black.png';
const PaymentFailure = () => {
  return (
    <>
      <div className="bg-black opacity-75 text-white w-full py-5">
        <a href="https://isha.sadhguru.org/sadhguru-exclusive">
          <p className="Back-to-donations">Back to Page</p>
        </a>
      </div>
      {/* <div className="bg-ishaBeige text-black py-10">HEADER 2 </div> */}
      <div className="bg-ishaBeige text-black py-10">
        <img alt="Sadhguru exclusive" src={logoImage} className="Isha-Logo---Black" />
      </div>
      <Container>
        <Col
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          xs={{ span: 12 }}
          style={{ backgroundColor: '#e8e8e8' }}
          className={'flex flex-col items-center mt-20 mb-20 p-20 '}>
          <p className="font-medium text-3xl mb-10">Payment Aborted</p>
          <svg width={70} height={70} viewBox="0 0 70 70" className="w-16 h-16 mb-10">
            <g
              fill="none"
              fillRule="evenodd"
              stroke="#B32727"
              strokeWidth={5.5}
              transform="translate(3 3)">
              <circle cx={32} cy={32} r={32} />
              <path strokeLinecap="round" d="M18.424 19l27.152 27m0-27L18.424 46" />
            </g>
          </svg>
          <p className="text-kala text-base text-center book-font mb-4">
            Many thanks for your interest towards payment for Sadhguru Exclusive.
            <br />
            Transaction timed out. Please{' '}
            <a style={{ color: 'blue' }} href={process.env.REACT_APP_ISO_HOST}>
              retry the payment
            </a>{' '}
            after 30 minutes.
            <br />
          </p>
        </Col>
      </Container>
      <div className="w-full">
        <Image fluid style={{ width: '100vw' }} src={footerImage} />
      </div>
      <div className="bg-black opacity-75 text-white w-full py-4" />
    </>
  );
};

export default PaymentFailure;
