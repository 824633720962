import TagManager from 'react-gtm-module';

const GTM = {
  init() {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
        dataLayerName: 'PageDataGpmsLayer',
      });
    }
  },

  sendDataLayer() {
    TagManager.dataLayer({
      dataLayer: {
        event: 'se_vp_pushed',
        // eslint-disable-next-line camelcase
        virtual_path: '/vp_personal_details_added',
      },
      dataLayerName: 'PageDataGpmsLayer',
    });
  },
};

export default GTM;
