import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { currentDomainUri } from '../../global/util';

function ESewa() {
    const params = useParams();
    const txnId = params.txnId;
    const orderAmount = params.orderAmount;

    useEffect(() => {
        const onClickNetbanking = async () => {
            try {
                document.getElementById('pid').value = txnId;
                document.getElementById('tAmt').value = orderAmount;
                document.getElementById('amt').value = orderAmount;

                var successURL = `${currentDomainUri()}/callback/esewa?q=su`;
                document.getElementById('su').value = successURL;

                var failureURL = `${currentDomainUri()}/callback/esewa?q=fu`;
                document.getElementById('fu').value = failureURL;

                var postURL = `https://${process.env.REACT_APP_ESEWA_HOST}/epay/main`;
                document.eSewaForm.action = postURL;

                document.eSewaForm.method = 'post';
                document.eSewaForm.submit();

            } catch (error) {
                console.log(error);
            }
        };

        onClickNetbanking();
    }, []);

    return (
        <>
            <form name="eSewaForm"  action="" method="POST"  className={'w-full'}>
                    <input value="" name="pid" id="pid" type="hidden"/>
                    <input value="" name="tAmt" id="tAmt" type="hidden"/>
                    <input value="" name="amt" id="amt" type="hidden"/>
                    <input value="" name="fu" id="fu" type="hidden" />
                    <input value="" name="su" id="su" type="hidden" />
                    <input value={process.env.REACT_APP_ESEWA_MID} name="scd" id="scd" type="hidden"/>
                    <input value="0" name="txAmt"  id="txAmt" type="hidden"/>
                    <input value="0" name="psc"  id="psc" type="hidden"/>
                    <input value="0" name="pdc" id="pdc" type="hidden"/>
            </form>
        </>
    );
};

export default ESewa;
