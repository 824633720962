import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

import './style.css';
import GTM from '../../global/gtm';
import footerImage from '../../assets/footer-image.jpg';
import logoImage from '../../assets/images/isha-logo-black.png';
import { currentDomainUri } from '../../global/util';

const PaymentMethods = () => {
  let postUrl = null;
  const [buttonVisible, setButtonVisible] = useState(true);
  const [buttonValueN, setButtonValueN] = useState('Net Banking');
  const [buttonValueC, setButtonValueC] = useState('Card');
  const [buttonValueU, setButtonValueU] = useState('UPI');


  const [userData, setPersonalInformation] = useState(null);
  const params = useParams();
  const defaultStyle =
    'justify-center mb-8 items-center h-20 book-font text-base border hover:border-primaryRed hover:bg-primaryRed cursor-pointer hover:text-white flex flex-col rounded border-kala';

  useEffect(() => {
    const callingSub = async () => {
      try {
        let userData1 = await axios.get('/api/sge/payment-request/' + params.uniqueId);
        let userData2 = userData1.data;

        let allData = {};

        allData = userData2;
        setPersonalInformation(allData);
      } catch (error) {
        console.log(error);
      }
    };

    callingSub();
  }, []);

  setTimeout(
      function () {
        window.location.href = currentDomainUri() + '/aborted/' + params.uniqueId + '/no-txn';
      },
      600000 //10 minute
  );

  const onClickMethod = async () => {
    // Your button's click handler logic
    try {
      setButtonVisible(false);
      setButtonValueN('');
      setButtonValueC('');
      setButtonValueU('');
      if (buttonValueN === 'Net Banking') {
        document.getElementById('gatewayPaymentType').value = 'NetBanking';
      } else {
        return;
      }
      postUrl = '/api/sge/payment-request/' + params.uniqueId;
      document.payTypeForm.action = postUrl;
      document.payTypeForm.method = 'post';

      GTM.sendDataLayer();

      document.payTypeForm.submit();
    } catch (error) {
      console.log(error);
    }
  };
  const onClickCard = async () => {
    try {
      setButtonVisible(false);
      setButtonValueN('');
      setButtonValueC('');
      setButtonValueU('');
      if (buttonValueC === 'Card') {
        document.getElementById('gatewayPaymentType').value = 'Card';
      } else {
        return;
      }
      document.getElementById('gatewayPaymentType').value = 'Card';
      postUrl = '/api/sge/payment-request/' + params.uniqueId;
      document.payTypeForm.action = postUrl;
      document.payTypeForm.method = 'post';

      GTM.sendDataLayer();

      document.payTypeForm.submit();
    } catch (error) {
      console.log(error);
    }
  };
  const onClickUPI = async () => {
    try {
      setButtonVisible(false);
      setButtonValueN('');
      setButtonValueC('');
      setButtonValueU('');
      if (buttonValueU === 'UPI') {
        document.getElementById('gatewayPaymentType').value = 'UPI';
      } else {
        return;
      }
      postUrl = '/api/sge/payment-request/' + params.uniqueId;
      document.payTypeForm.action = postUrl;
      document.payTypeForm.method = 'post';

      GTM.sendDataLayer();

      document.payTypeForm.submit();
    } catch (error) {
      console.log(error);
    }
  };


  return (
      <>
        <div className="bg-ishaBeige text-black py-10">
          <img alt="Isha-Logo---B" src={logoImage} className="Isha-Logo---Black" />
        </div>
        <div style={{ marginLeft: '25vw' }} className="col-6">
          <form name="payTypeForm" action="" method="post" className={'w-full'}>
            <input type="hidden" id="gatewayPaymentType" name="gatewayPaymentType" value="" />
          </form>
          <br />
          <br />
          <Row className={'w-full mb-4 justify-center'}>
            <br />
            {userData != null && userData.trialDays > 0 ? (
                <p className="text-xl font-semibold text-kala">
                  You are 1 step away from your free trial!
                </p>
            ) : null}
            {userData != null && userData.trialDays === 0 ? (
                <p className="text-lg font-medium text-kala">
                  Pay {userData.currency} {userData.amount} ({userData.type}) for {params.prodDesc}
                </p>
            ) : null}
          </Row>
          <Row className={'w-full justify-between px-12 mb-4 lg:flex hidden'}>
            <Col className="flex flex-col items-center" lg={4}>
              <svg width={54} height={54} viewBox="0 0 54 54" className={'mb-4'}>
                <defs>
                  <path id="prefix__a" d="M.787.431h.417v.857H.787z" />
                </defs>
                <g fill="none" fillRule="evenodd" opacity={0.2}>
                  <path
                      stroke="#cf4520"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M34.112 32.84c-.066-4.304-.613-5.57-4.7-6.357.83-.832 1.372-2.09 1.372-3.522 0-2.503-.534-4.532-3.628-4.532-3.094 0-3.627 2.029-3.627 4.532 0 1.452.557 2.73 1.407 3.56-3.866.781-4.433 2.053-4.508 6.175-.007.347-.01.365-.011.325v.458s1.01 2.092 6.852 2.092c5.842 0 6.851-2.092 6.851-2.092v-.338a3.356 3.356 0 01-.008-.303z"
                  />
                  <circle cx={27} cy={27} r={27} stroke="#cf4520" />
                </g>
              </svg>
              <p className="text-base text-primaryRed medium-font">Personal Info</p>
            </Col>
            <Col className="flex flex-col items-center" lg={4}>
              <svg width={54} height={54} viewBox="0 0 54 54" className={'mb-4'}>
                <g fill="none" fillRule="evenodd" opacity="0.2">
                  <circle cx={27} cy={27} r={27} stroke="#cf4520" />
                  <path
                      d="M17.477 33.378h19.728V20.466H17.477zm.636-9.178h18.774"
                      stroke="#cf4520"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </g>
              </svg>
              {userData != null && userData.trialDays > 0 ? (
                  <p className="text-base text-cccGray medium-font">Authorization</p>
              ) : <p className="text-base text-cccGray medium-font">Payment</p>}
            </Col>
            <Col className="flex flex-col items-center" lg={4}>
              <svg width={54} height={54} viewBox="0 0 54 54" className={'mb-4'}>
                <g fill="none" fillRule="evenodd" opacity={0.2}>
                  <circle cx={27} cy={27} r={27} stroke="#cf4520" />
                  <g stroke="#cf4520" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M19.533 34.289h15.142V19.533H19.533z" />
                    <path d="M23.869 26.289l1.6 2.667 9.245-4.09" />
                  </g>
                </g>
              </svg>
              <p className="text-base text-cccGray medium-font">Finish</p>
            </Col>
          </Row>
          <Row className={'w-full px-16 lg:flex hidden'}>
            <div className="w-full h-3 rounded-full b g-cccGray" />
          </Row>
          <Row className={'w-full mb-10 -mt-3 px-16 lg:flex hidden'}>
            <div className={'h-3 w-2/3 rounded-full bg-primaryRed'} />
          </Row>
          <Row className={'w-full mb-12 justify-center'}>
            <br />
            <p className="text-lg font-medium text-kala">Select a Payment Type</p>
          </Row>

          {!buttonVisible && (
              <Row className={'w-full mb-4 justify-center'}>
                <br />
                <>
                  Loading Please Wait...
                </>
              </Row>
          )}
          <Row className={'w-full mb-4 justify-center'}>
            <br />
            <Col sm={4}>
              <div className={defaultStyle} onClick={onClickMethod}>
                <button onClick={onClickMethod}   value={buttonValueN} disabled={!buttonVisible}>

                  {!buttonVisible ?  <><Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                  /></>: buttonValueN}
                </button>
              </div>
            </Col>
            <Col sm={4}>
              <div className={defaultStyle} onClick={onClickCard}>
                <button onClick={onClickCard}  value={buttonValueC} disabled={!buttonVisible}>

                  {!buttonVisible ?  <><Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                  /></>: buttonValueC }
                </button>
              </div>
            </Col>
            <Col sm={4}>
              <div className={defaultStyle} onClick={onClickUPI}>
                <button onClick={onClickUPI} value={buttonValueU} disabled={!buttonVisible}>

                  {!buttonVisible ?  <><Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                  /></>: buttonValueU }
                </button>
              </div>
            </Col>
          </Row>


          <Row className={'w-full mb-12 justify-center'}>
            {userData != null && userData.trialDays === 0 ? (
                <div>
                  <p className="md:text-md xs:text-md lg:text-lg When-the-monthly-pay">
                    For Net Banking transactions, payment authorization could take up to 5 working days.
                    The first payment will be processed immediately after authorization is complete.
                  </p>
                  <p className="md:text-md xs:text-md lg:text-lg When-the-monthly-pay">
                    For Net Banking, Card and UPI transactions, you may be charged between Rs. 1 to Rs.
                    5 for verification during the process. Once verified, these charges will be
                    reversed.
                  </p>
                  <p className="md:text-md xs:text-md lg:text-lg When-the-monthly-pay">
                    <b>
                      <br />
                      Due to recent updates in the RBI guidelines, cards are currently not supported for
                      making recurring payments. We recommend you to choose Net Banking or UPI for your
                      monthly subscription.
                    </b>
                  </p>
                </div>
            ) : null}
            {userData != null && userData.trialDays > 0 ? (
                <div>
                  <p className="md:text-md xs:text-md lg:text-lg When-the-monthly-pay">
                    Your free trial begins only after your payment authorization is complete.
                    Therefore, we recommend payment by Card or UPI as Net Banking may take upto 5 working days to process.<br/>
                  </p>
                  <p className="md:text-md xs:text-md lg:text-lg When-the-monthly-pay">
                    You may be charged INR 1 - INR 5 for verification during the process,
                    which will be refunded upon a successful authorization.
                  </p>
                  <p className="md:text-md xs:text-md lg:text-lg When-the-monthly-pay">
                    If you choose UPI, you may be asked to approve an autopay request for {userData.currency} {userData.amount}. Do not worry, you will be
                    charged this amount only after the trial expires.You can cancel anytime during the trial.<br/>
                  </p>
                </div>
            ) : null}
          </Row>

          <br />
          <br />
          <br />
          <br />
        </div>
        <div style={{ objectFit: 'contain' }}>
          <img src={footerImage} alt="bg-black " style={{ width: '100%' }} />
        </div>
        <div className="bg-black opacity-75 text-white w-full py-4" />
      </>
  );
};

export default PaymentMethods;
