import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function FonePay() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const amt = queryParams.get('AMT');
  const crn = queryParams.get('CRN');
  const dt = queryParams.get('DT');
  const dv = queryParams.get('DV');
  const md = queryParams.get('MD');
  const pid = queryParams.get('PID');
  const prn = queryParams.get('PRN');
  const r1 = queryParams.get('R1');
  const r2 = queryParams.get('R2');
  const ru = queryParams.get('RU');

  useEffect(() => {
    const onClick = async () => {
      try {
        document.getElementById('RU').value = ru;
        document.getElementById('PID').value = pid;
        document.getElementById('PRN').value = prn;
        document.getElementById('AMT').value = amt;
        document.getElementById('CRN').value = crn;
        document.getElementById('R1').value = r1;
        document.getElementById('R2').value = r2;
        document.getElementById('MD').value = md;
        document.getElementById('DV').value = dv;
        document.getElementById('DT').value = dt;

        var getURL = `https://${process.env.REACT_APP_FONEPAY_HOST}/api/merchantRequest`;
        document.fonePayForm.action = getURL;
        document.fonePayForm.method = 'get';
        document.fonePayForm.submit();

      } catch (error) {
        console.log(error);
      }
    };

    onClick();
  }, []);

  return (
      <>
        <form name="fonePayForm"  action="" method="GET"  className={'w-full'}>
          <input value="" name="RU" id="RU" type="hidden"/>
          <input value="" name="PID" id="PID" type="hidden"/>
          <input value="" name="PRN" id="PRN" type="hidden"/>
          <input value="" name="AMT" id="AMT" type="hidden"/>
          <input value="" name="CRN" id="CRN" type="hidden"/>
          <input value="" name="R1" id="R1" type="hidden"/>
          <input value="" name="R2" id="R2" type="hidden"/>
          <input value="" name="MD" id="MD" type="hidden"/>
          <input value="" name="DV" id="DV" type="hidden"/>
          <input value="" name="DT" id="DT" type="hidden"/>
        </form>
      </>
  );
};

export default FonePay;
