import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

let postUrl = null;

const createDebug = require('debug');
const debug = createDebug('foo');

const PaymentMethods = () => {
  const params = useParams();
  const code = params.code;

  debug('code>>>>>>>>>>>>>: %h', new Buffer(code));
  useEffect(() => {
    const onClickNetbanking = async () => {
      try {
        document.getElementById('encRequest').value = params.encData;
        document.getElementById('access_code').value = params.code;

        postUrl = `https://${process.env.REACT_APP_CCAVENUE_HOST}/transaction/transaction.do`;
        document.payTypeForm.action = postUrl;
        document.payTypeForm.method = 'post';
        document.payTypeForm.submit();
      } catch (error) {
        console.log(error);
      }
    };

    onClickNetbanking();
  }, []);

  return (
    <>
      <form name="payTypeForm" action="" method="post" className={'w-full'}>
        <input type="hidden" name="command" id="command" value="initiateTransaction" />
        <input type="hidden" name="encRequest" id="encRequest" value="" />
        <input
          type="hidden"
          name="merchant_id"
          id="merchantId"
          value={process.env.REACT_APP_CCAVENUE_MID}
        />
        <input type="hidden" name="access_code" id="access_code" value="" />
      </form>
    </>
  );
};

export default PaymentMethods;
